import React from 'react';
import LazyLoad from 'react-lazyload'; // Import LazyLoad
import './GalleryImage.css';

const GalleryImage = React.memo(({ imageLink, bottomSideHeading }) => {
    return (
        <div className='imageContainer'>
            {bottomSideHeading && (
                <div className='bottomSideHeading'>
                    {bottomSideHeading}
                </div>
            )}

          
                <img
                    
                    className='imageLink'
                    src={imageLink}
                    alt='Gallery Image'
                    loading="lazy"
                />
            
        </div>
    );
});

export default GalleryImage;
