
import React from 'react';
import DOMPurify from 'dompurify'; // Import DOMPurify
import './Disclaimer.css'
import { data } from '../../../config/data';
const Disclaimer = () => {
    const sanitizedDisclaimer = DOMPurify.sanitize(data?.disclaimer || '');
    return (
        <div
            style={{
                padding: "30px"
            }} className="policy-container"
            dangerouslySetInnerHTML={{ __html: sanitizedDisclaimer }}
        
        >
        
        </div>
    );
};

export default Disclaimer;
