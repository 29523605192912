import React from 'react'
import Heading from '../../component/Heading/Heading'
import { data } from '../../../config/data'

import ResponsiveCarousel from '../../component/ResponsiveCarousel/ResonsiveCarousel'
import IconCarsoul from './IconCarasoul'

const AmenitiesSection = ({ setIsThankYouPage }) => {
  
  return (
    <div
      className='container'
    
    >
      <Heading
        heading={
          data?.amenities?.heading
        }
      />
      {
        data?.amenities?.image ? 
          
     <ResponsiveCarousel
       images={ data?.amenities?.images}
        />  : 
          <IconCarsoul
            icons={
              data?.amenities?.icons
            }

          />
      }
      


    </div>
  )
}

export default AmenitiesSection