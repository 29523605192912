import React from 'react'
import './Heading.css'
const Heading = ({heading}) => {
  return (
      <div
          className='heading'
      >
          {heading}
    </div>
  )
}

export default Heading