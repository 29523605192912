import React, { useEffect, useState } from 'react';
import { FaCar } from 'react-icons/fa';
import { HiCurrencyRupee } from 'react-icons/hi';
import { RiCustomerService2Fill } from 'react-icons/ri';
import {  IoCheckmarkCircleSharp } from 'react-icons/io5';
import { message as antdMessage } from 'antd';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Logo from '../Logo/Logo';
import ButtonBackground from '../Buttons/ButtonBackground/ButtonBackground';

import { apiCalling } from '../../../config/apicalling';


const Popup = ({ setIsThankYouPage, dialCode, data }) => {
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const [countryCode, setCountryCode] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [message, setMessage] = useState('');

    const [isSmallScreen, setIsSmallScreen] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 955);
        };

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const countryLengths = {
        "+93": 9, "+355": [8, 9], "+213": 9, "+376": 6, "+244": 9, "+54": 10, "+374": 8,
        "+61": 9, "+43": 10, "+994": 9, "+973": 8, "+880": 10, "+375": 9, "+32": 9,
        "+501": 7, "+229": 8, "+975": 8, "+591": 8, "+387": 8, "+267": 7, "+55": 10,
        "+673": 7, "+359": 9, "+226": 8, "+257": 8, "+238": 7, "+855": 9, "+237": 9,
        "+1": 10, "+236": 8, "+235": 8, "+56": 9, "+86": 11, "+57": 10, "+269": 7,
        "+242": 9, "+506": 8, "+385": 9, "+53": 8, "+357": 8, "+420": 9, "+45": 8,
        "+253": 8, "+1-767": 10, "+1-809": 10, "+670": 7, "+593": 9, "+20": 10,
        "+503": 8, "+240": 9, "+291": 7, "+372": 7, "+268": 8, "+251": 9, "+679": 7,
        "+358": 10, "+33": 9, "+241": 7, "+220": 7, "+995": 9, "+49": 10, "+233": 9,
        "+30": 10, "+1-473": 10, "+502": 8, "+224": 9, "+245": 7, "+592": 7, "+509": 8,
        "+504": 8, "+36": 9, "+354": 7, "+91": 10, "+62": 10, "+98": 10, "+964": 10,
        "+353": 9, "+972": 9, "+39": 10, "+1-876": 10, "+81": 10, "+962": 9,
        "+254": 10, "+686": 5, "+965": 8, "+996": 9, "+856": 10, "+371": 8, "+961": 8,
        "+266": 8, "+231": 7, "+218": 10, "+423": 7, "+370": 8, "+352": 9, "+261": 9,
        "+265": 9, "+60": 9, "+960": 7, "+223": 8, "+356": 8, "+692": 7, "+222": 8,
        "+230": 7, "+52": 10, "+691": 7, "+373": 8, "+377": 9, "+976": 8, "+382": 8,
        "+212": 9, "+258": 9, "+95": 9, "+264": 9, "+674": 7, "+977": 10, "+31": 9,
        "+64": 9, "+505": 8, "+227": 8, "+234": 10, "+389": 8, "+47": 8, "+968": 8,
        "+92": 10, "+680": 7, "+970": 9, "+507": 8, "+675": 7, "+595": 9, "+51": 9,
        "+63": 10, "+48": 9, "+351": 9, "+974": 8, "+40": 10, "+7": 10, "+250": 9,
        "+1-869": 10, "+1-758": 10, "+1-784": 10, "+685": 5, "+378": 9, "+239": 7,
        "+966": 9, "+221": 9, "+381": 8, "+248": 7, "+232": 8, "+65": 8, "+421": 9,
        "+386": 8, "+677": 5, "+252": 7, "+27": 9, "+82": 10, "+211": 9, "+34": 9,
        "+94": 9, "+249": 9, "+597": 6, "+46": 9, "+41": 9, "+963": 9, "+886": 9,
        "+992": 9, "+255": 9, "+66": 9, "+228": 8, "+676": 5, "+1-868": 10, "+216": 8,
        "+90": 10, "+993": 8, "+688": 5, "+256": 9, "+380": 9, "+971": 9, "+44": 10,
        "+598": 9, "+998": 9, "+678": 5, "+379": 8, "+58": 10, "+84": 9, "+967": 9,
        "+260": 9, "+263": 9
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!name) {
            antdMessage.error('Please enter your name.');
            return;
        }
        if (!email) {
            antdMessage.error('Please enter your email.');
            return;
        }
        if (!number) {
            antdMessage.error('Please enter your phone number.');
            return;
        }

        const formattedNumber = `+${countryCode}-${number.replace(countryCode, '')}`;

        const numberLength = formattedNumber.replace(/^\+?\d{1,3}-/, '').length;
        const expectedLengths = countryLengths[`+${countryCode}`];

        let isValidLength;
        if (expectedLengths) {
            isValidLength = Array.isArray(expectedLengths)
                ? expectedLengths.includes(numberLength)
                : expectedLengths === numberLength;
        } else {
            isValidLength = numberLength > 0;
        }

        if (!isValidLength) {
            antdMessage.error(`Phone number should be ${Array.isArray(expectedLengths) ? expectedLengths.join(' or ') : expectedLengths} digits long.`);
            return;
        }
        const formData = {
            name,
            email,
            phone: formattedNumber,
            message,
            reason: "Register Here and Avail the Best Offers!!"
        };
        setLoading(true)
        const data = await apiCalling(formData)
        if (data === 'success') {
            setIsThankYouPage(true)
        } else {
            antdMessage.error("Something went wrong please try again later.")
        }
        setLoading(false)
    };
    const handlePhoneInputChange = (value, data) => {
        setNumber(value);
        setCountryCode(data.dialCode);
    };


    return (
        <div
           
        >
            <div
                style={
              !  isSmallScreen?
                    {
                    display: 'grid',
                    width: "100%",
                    gridTemplateColumns: "1fr 10fr 6fr",
                    height : '430px'
                        } : {
                            display: 'grid',
                            width: "100%",
                            gridTemplateColumns: "1fr 8fr ",
                            height: '430px'

                        }
            }
            
            >
                <div
                    style={{
                        backgroundColor: "rgba(249,249,249)",
                        padding : '5px',
                        display: 'flex',
                        // justifyContent: 'center',
                        // alignItems: "center",
                        flexDirection: "column"
                        
                        
                    }}
                >

              
                
                <h3
                        style={{
                            textAlign: 'center',
                            padding  :"20px 10px"
                }}
                
                    >We Promise</h3>
                    <div
                        style={{
                            margin: "20px 10px"
                        }}
                    >
                        <RiCustomerService2Fill
                            style={{
                                fontSize: '2rem',
                                display :'flex',
                                textAlign: "center",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                color : "rgba(2,43,45)"
                            }}
                    
                        
                        />
                        <h4
                        
                            style={{

                                display: 'flex',
                                textAlign: "center",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",

                            }}
                        >Instant Call Back</h4>
                </div>
                    <div
                        style={{
                            margin: "20px 10px"
                        }} 
                    
                    >
                        <FaCar
                            style={{
                                fontSize: '2rem',
                                display: 'flex',
                                textAlign: "center",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                color: "rgba(2,43,45)"
                            }}
                        />
                        <h4
                            style={{

                                display: 'flex',
                                textAlign: "center",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",

                            }}
                        >Free Site Visit</h4>
                </div>
                    <div
                        style={{
                            margin: "20px 10px"
                        }}
                    
                    >
                        <HiCurrencyRupee
                            style={{
                                fontSize: '2rem',
                                display: 'flex',
                                textAlign: "center",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                color: "rgba(2,43,45)"
                            }}
                        
                        />
                        <h4
                            style={{
                                
                                display: 'flex',
                                textAlign: "center",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                               
                            }}
                        
                        >Unmatched Price</h4>
                </div>

                </div>



                <div
                    style={{
                        marginTop: "10px",
                        padding : '10px'
                }}
                >
                    <Logo />
                    <div
                        style={{
                            textAlign: "center",
                        
                            border: "2px solid rgba(2,43,45)",
                            borderRadius: "25px",
                            fontWeight: "bold",
                            width: "100%",
                            margin: "auto",
                            marginTop: "20px",
                            fontSize : "14px"
                            
                    }}
                    
                    >
                        Register Here and Avail the <br />
                        <span
                            style={{
                                color :"red"
                            }}
                            
                        
                        >Best Offers!!</span>
                    </div>
                    <div>
                        <form onSubmit={handleSubmit}>
                          
                            <div style={{ display: 'flex', borderBottom: '1px solid gray', margin: 'auto', width: '80%', color: 'gray', marginBottom: '20px',marginTop :'30px' }}>
                        
                                <input
                                    placeholder='Name'
                                    
                                    style={{ border: "none", outline: 'none', paddingBottom: '8px', fontSize :"16px" }}  value={name} onChange={(e) => setName(e.target.value)} required />
                            </div>
                            <div style={{ display: 'flex', borderBottom: '1px solid gray', margin: 'auto', width: '80%', color: 'gray', marginBottom: '16px' }}>
                        
                                <input
                                    placeholder='Email'
                                    
                                    type="email" style={{ border: "none", outline: 'none', paddingBottom: '8px', fontSize :"16px" }}  value={email} onChange={(e) => setEmail(e.target.value)} required />
                            </div>
                            <div style={{ display: 'flex', borderBottom: '1px solid gray', margin: 'auto', width: '80%', color: 'gray', marginBottom: '20px' }}>
                                <PhoneInput
                                    country={dialCode?.toLowerCase()}
                                    value={number}
                                    onChange={handlePhoneInputChange}

                                    required
                                    inputStyle={{ border: 'none', outline: 'none',fontSize :"16px" , width: '100%' }}
                                />

                            </div>
                            <div style={{ display: 'flex', marginTop: '20px', margin: 'auto', width: '80%', color: 'gray', flexDirection: 'column', marginBottom: '10px', borderBottom: '1px solid GRAY' }}>
                                {/* <label
                                    className='timeColor '
                                    style={{
                                        fontSize: "10px",
                                        marginBottom: '10px'
                                    }}
                                >Let us know a time (in IST) at which our team can set up a Video Conferencing call</label> */}
                                <textarea
                                   placeholder= {data?.formData?.placeHolder}
                                    style={{ border: "none", outline: 'none', paddingBottom: '8px', fontSize: "16px" }} value={message} onChange={(e) => setMessage(e.target.value)} 
                                    rows = {data?.formData?.popup?.rows}
                                
                                />
                            </div>
                            <div style={{ width: '80%', margin: 'auto', marginTop: '10px' }}>
                                <ButtonBackground
                                    loading={loading}
                                
                                    text={'Enquire Now'} type="primary" htmlType="submit" />
                            </div>
                        </form>
                    </div>
                </div>


                {
                    !isSmallScreen && <div

                        style={{
                            backgroundColor: "rgba(2,43,45)",
                            color: 'white',
                            padding: "10px",
                            textAlign: "center",
                            paddingTop: "60px",
                            fontSize : '13px'

                        }}

                    >
                        <h2>Get Information about Availabilities</h2>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                marginTop: "50px"
                            }}

                        >


                            <div
                                style={{

                                    width: "100%",
                                    display: 'flex',
                                    // justifyContent: 'center',
                                    alignItems: "center",
                                    marginTop: "10px",
                                    fontSize: "16px"
                                }}


                            >
                                <IoCheckmarkCircleSharp /> Available Units
                            </div>
                            <div
                                style={{

                                    width: "100%",
                                    display: 'flex',
                                    // justifyContent: 'center',
                                    alignItems: "center",
                                    marginTop: "10px",
                                    fontSize: "16px"

                                }}
                            >
                                <IoCheckmarkCircleSharp /> Payment Plan
                            </div>
                            <div
                                style={{

                                    width: "100%",
                                    display: 'flex',
                                    // justifyContent: 'center',
                                    alignItems: "center",
                                    marginTop: "10px",
                                    fontSize: "16px"
                                }}

                            >
                                <IoCheckmarkCircleSharp /> Floor Plan
                            </div>
                        </div>
                    </div>

                    
                }
                




            </div>
        
{/* 
            <div
                
                style={{
                    display: 'flex',
                    justifyContent: "center",
                    alignItems : "center",
                    backgroundColor: "rgba(2,43,45)",
                    color: "white",
                    position: 'relative',
                    margnTop :"10px",
                    bottom: " -13px",
                    padding : "5px"
                }}
            
            >
                <IoCall
                    style={{
                        fontSize: "14px",
                        marginRight : "10px",
                        
                    }}
                />
                <h4>Call Us</h4>
            </div> */}
        </div>
    );
};

export default Popup;
