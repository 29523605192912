import React from 'react';
import DOMPurify from 'dompurify'; // Import DOMPurify
import './FooterSection.css';
import { data } from '../../../config/data';
import { useNavigate } from 'react-router-dom';

const FooterSection = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    window.open("/disclaimer", "_blank");
  };

  // Sanitize the disclaimer HTML content
  const sanitizedDisclaimer = DOMPurify.sanitize(data?.footer?.disclimer || '');

  return (
    <div
      style={{
        backgroundColor: "transparent"
      }}
      className='footer container'
    >
      <h5
        style={{
          textAlign: "center",
          padding: "10px 0 "
        }}
      >
        {data?.footer?.topLine}
      </h5>
      <h5
        style={{
          textAlign: "center",
          padding: "10px 0 "
        }}
      >
        {data?.footer?.bottomLine} 
        {" "}
        <a
          style={{
            color: 'blue',
            cursor: "pointer",
            textDecoration : "none"
          }}
          href={data?.footer?.link}
        > {"("}{data?.footer?.link}{")"}</a> 
      </h5>
      <p
        style={{
          fontSize : "12px"
        }}
        className= 'footerSectionDisclaimer'
      
      >
        <span>Disclaimer: <span dangerouslySetInnerHTML={{ __html: sanitizedDisclaimer }} /></span>
       
      </p>
      <p
        style={{
          textAlign: "center",
          padding: "5px 0 ",
          fontSize: "12px",
          cursor: "pointer",
          color: "blue"
        }}
        onClick={handleNavigate}
      >
        Disclaimer & Privacy Policy
      </p>
      <p
        style={{
          textAlign: "center",
          fontSize: "12px"
        }}

      >
        {data?.footer?.footerAllRight}
      </p>
    </div>
  );
};

export default FooterSection;
