import React, { useEffect, useState } from 'react'
import HeroSection from '../../Sections/HeroSection/HeroSection'
import PriceSection from '../../Sections/PriceSection/PriceSection'
import SiteFloorSection from '../../Sections/SiteFloorSection/SiteFloorSection'
import AmenitiesSection from '../../Sections/AmenitiesSection/AmenitiesSection'
import GallerySection from '../../Sections/GallerySection/GallerySection'
import LocationSection from '../../Sections/LocationSection/LocationSection'
import VirtualSiteSection from '../../Sections/VirtualSiteSection/VirtualSiteSection'
import Navbar from '../../Sections/Navbar/Navbar'
import FooterSection from '../../Sections/FooterSection/FooterSection'
import CallingFooter from '../../component/CallingFooter/CallingFooter.jsx'
import AboutTheDeveloper from '../../Sections/abouthedeveloperSection/AboutTheDeveloper.jsx'


const LeftSide = ({ setIsThankYouPage,  dialCode }) => {
    const [isBigScreen, setIsBigScreen] = useState(window.innerWidth >= 700);

    useEffect(() => {
        const handleResize = () => {
            setIsBigScreen(window.innerWidth >= 700);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
  

  return (
      <div>
          <Navbar setIsThankYouPage={setIsThankYouPage} />
          <section id="heroSection"> 
              <HeroSection dialCode={dialCode} setIsThankYouPage={setIsThankYouPage} />
          </section>
          <section id="priceSection">
              <PriceSection dialCode={dialCode} setIsThankYouPage={setIsThankYouPage} />
          </section>
          <section id="SiteFloorSection">
              <SiteFloorSection dialCode={dialCode} setIsThankYouPage={setIsThankYouPage} />
          </section>
          <section id="amenitiesSection">
              <AmenitiesSection dialCode={dialCode}  setIsThankYouPage={setIsThankYouPage} />
          </section>
          <section id="gallerySection">
              <GallerySection dialCode={dialCode} setIsThankYouPage={setIsThankYouPage} />
          </section>
          <section id="locationSection">
              <LocationSection dialCode={dialCode} setIsThankYouPage={setIsThankYouPage} />
          </section>
          <section id="virtualSiteSection">
              <VirtualSiteSection dialCode={dialCode} setIsThankYouPage={setIsThankYouPage} />
          </section>
          <AboutTheDeveloper/>
          <FooterSection />
          {
              !isBigScreen && 
              <CallingFooter
                  setIsThankYouPage={setIsThankYouPage} 
                  dialCode={dialCode} />

          }
      
      </div>
  )
}

export default LeftSide