import React, { useState } from 'react';
import { Row, Col, Modal } from 'antd';
import Heading from '../../component/Heading/Heading';
import { data } from '../../../config/data';
import VideoView from '../../component/VideoView/VideoView';
import PopupDynamic from '../../component/popup/PopupDynamic';

const VirtualSiteSection = ({ setIsThankYouPage, dialCode }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const handleVideoClick = (item) => {
    console.log(item, 'the item is')
    setSelectedVideo(item);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setSelectedVideo(null);
  };

  return (
    <div className='container'>
      <Heading heading={data?.virtualSiteTour?.heading} />
      <Row gutter={[16, 16]}>
        {data?.virtualSiteTour?.items?.map((item, index) => (
          <Col style={{
            margin: '10px auto'
          }} key={index} xs={23} md={10}
            onClick={() => handleVideoClick(item)}

          >
            <VideoView
              heading={item?.heading}
              subHeading={item?.subHeading}
              topSideHeading={item?.topSideHeading}
              backgroundImage={item?.backgroundImage}
            />
          </Col>
        ))}
      </Row>
      <Modal
        visible={modalVisible}

        onCancel={closeModal}
        footer={null}
        dialCode={dialCode}
      >

        <PopupDynamic
          data={data}
          heading={selectedVideo?.popupData?.heading}
          button={selectedVideo?.popupData?.button}
          setIsThankYouPage={setIsThankYouPage}
          dialCode={dialCode}
        />

      </Modal>
    </div>
  );
};

export default VirtualSiteSection;
