import React, { useState, useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import ButtonBackground from '../Buttons/ButtonBackground/ButtonBackground';
import { Modal, message as antdMessage } from 'antd';
import './SimpleForm.css'
import PopupDynamic from '../popup/PopupDynamic';
import { apiCalling } from '../../../config/apicalling';

const SimpleForm = ({ setIsThankYouPage, dialCode, data }) => {
    const { cardData, formData } = data;
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [message, setMessage] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEnquireModalVisible, setIsEnquireModalVisible] = useState(false); // S
    const [countryCode, setCountryCode] = useState('');
    const [loading, setLoading] = useState(false);
    const handleEnquireNow = () => {
        setIsEnquireModalVisible(true); // Show the Enquire Now modal
    };

    const handleEnquireModalOk = () => {
        setIsEnquireModalVisible(false);
    };

    const handleEnquireModalCancel = () => {
        setIsEnquireModalVisible(false);
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!name) {
            antdMessage.error('Please enter your name.');
            return;
        }
        if (!email) {
            antdMessage.error('Please enter your email.');
            return;
        }
        if (!number) {
            antdMessage.error('Please enter your phone number.');
            return;
        }
        const formdata = {
            name,
            email,
            phone: number,
            reason: "Enquiry",
            message
        };
        setLoading(true)
        const dataa = await apiCalling(formdata, data)
        if (dataa === 'success') {
            setIsThankYouPage(true)
        } else {
            antdMessage.error("Something went wrong please try again later.")
        }
        setLoading(false)
    };
    const handlePhoneInputChange = (value, data) => {
        setNumber(value);
        setCountryCode(data.dialCode);
    };

    const handleModalOk = () => {
        setIsModalVisible(false);
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
    };
    const [isBigScreen, setIsBigScreen] = useState(window.innerWidth >= 890);




    useEffect(() => {
        const handleResize = () => {
            setIsBigScreen(window.innerWidth >= 890);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div
            className={`${isBigScreen ? 'SimpleFormBig' : 'SimpleFormSmall'}`}
        >
            <div>
                <div

                    className={`${isBigScreen ? 'SimpleFormBigHeading' : 'SimpleFormSmallHeading'}`}

                >
                    {cardData?.heading}
                </div>
                <div
                    className={`${isBigScreen ? 'SimpleFormBigMainHeading' : 'SimpleFormSmallMainHeading'}`}
                >
                    <h1

                    >
                        {
                            cardData?.mainHeading}
                    </h1>
                    <div
                        className='cardAddress  '


                    >
                        <h4

                            className={`${isBigScreen ? 'SimpleFormBigAddress' : 'SimpleFormSmallAdress'}`}

                        >
                            {cardData?.address}
                        </h4>
                        <h4
                            style={{
                                textAlign: "center"
                            }}
                        >
                            {cardData?.subAddress}
                        </h4>
                    </div>
                </div>
                <div
                    className={`${isBigScreen ? 'SimpleFormBigLandArea' : 'SimpleFormSmallLandArea'}`}
                    style={{
                        backgroundColor: 'rgba(232,232,232)',
                        padding: "10px 0"
                    }}
                >
                    {cardData?.fields.map((field) => (
                        <div key={field.key} className={isBigScreen ? 'SimpleFormBigLandAreaHeading' : 'SimpleFormSmallLandAreaHeading'}>
                            <p>{field.label}</p>
                            <h4>{field.key}</h4>
                        </div>
                    ))}

                </div>
                <div
                    className={`${isBigScreen ? 'SimpleFormBigBackground' : 'SimpleFormSmallBackground'}`}

                >
                    <div
                        style={{
                            textAlign: 'center',
                        }}
                    >
                        {cardData?.buttonData?.discount}
                    </div>
                    <div
                        style={{
                            textAlign: 'center',
                        }}
                    >
                        {cardData?.buttonData?.edition}
                    </div>
                    <div
                        style={{
                            textAlign: 'center',
                        }}
                    >
                        {cardData?.buttonData?.plantTime}
                    </div>
                </div>
                <div
                    className={`${isBigScreen ? 'SimpleFormBigDescription' : 'SimpleFormSmallDescription'}`}

                >
                    <p

                    >
                        {cardData?.description}
                    </p>
                    <h1

                    >₹ {cardData?.price}</h1>
                    <div
                        onClick={() => { handleEnquireNow() }}
                        style={{
                            width: '200px',
                            margin: "auto",

                        }}
                    >
                        <ButtonBackground text={'Enquire Now'} />
                    </div>
                    <div
                        style={{
                            textAlign: 'center',
                            fontSize: "10px",

                            margin: '10px 0'
                        }}
                    >
                        {cardData?.rera}
                    </div>
                </div>
                {
                    !isBigScreen &&
                    <div
                        style={{
                            marginTop: "30px",
                            backgroundColor: "rgba(219,228,233)"
                        }}

                    >
                        <h2
                            style={{
                                textAlign: "center",
                                // marginTop: "25px",
                                paddingTop: "17px"
                            }}
                        >Enquire Now</h2>


                        <form
                            id={data.projectId + 'bottomheroform'}
                            style={{
                                paddingBottom: "30px"
                            }}
                            onSubmit={handleSubmit}>

                            <div style={{
                                display: 'flex',
                                borderBottom: '1px solid gray',
                                margin: 'auto',
                                marginTop: "20px",
                                width: '90%',
                                padding: '5px',
                                backgroundColor: "white",
                                height: '40px',

                                color: 'gray',
                                alignItems: 'center'
                            }}>
                                {/* <label>Name</label> */}
                                <input
                                    placeholder='Name'
                                    style={{ border: "none", width: "100%", outline: 'none', paddingLeft: "8px", fontSize: "16px" }} value={name} onChange={(e) => setName(e.target.value)} required />
                            </div>
                            <div style={{
                                display: 'flex',
                                borderBottom: '1px solid gray',
                                margin: 'auto',
                                marginTop: "10px",
                                width: '90%',
                                padding: '5px',
                                backgroundColor: "white",
                                height: '40px',
                                color: 'gray',
                                alignItems: 'center'
                            }}>
                                {/* <label>Email</label> */}
                                <input placeholder='Email'

                                    type="email" style={{ width: "100%", border: "none", paddingLeft: "8px", outline: 'none', fontSize: "16px" }} onChange={(e) => setEmail(e.target.value)} required />
                            </div>
                            <div style={{
                                display: 'flex',
                                borderBottom: '1px solid gray',
                                margin: 'auto',
                                marginTop: "10px",
                                width: '90%',
                                padding: '5px',
                                backgroundColor: "white",
                                height: '40px',
                                color: 'gray',
                                alignItems: 'center'
                            }}>
                                {/* <label>Email</label> */}
                                <input placeholder={data?.formData?.phonePlaceholder}
                                    value={number}
                                    type="text" style={{ width: "100%", border: "none", paddingLeft: "8px", outline: 'none', fontSize: "16px" }} onChange={(e) => setNumber(e.target.value)} required />
                            </div>

                            <div

                                className=""

                                style={{

                                    borderBottom: '1px solid gray',
                                    margin: 'auto',
                                    marginTop: "10px",
                                    width: '90%',
                                    padding: '5px',
                                    backgroundColor: "white",
                                    color: 'gray',
                                    alignItems: 'center',
                                    marginBottom: "10px",
                                    border: "none"
                                }}

                            >
                                <textarea

                                    placeholder={formData?.placeHolder}
                                    style={{ fontSize: '16px', border: "none", outline: 'none', width: '100%', resize: 'none', paddingLeft: "5px" }} value={message} onChange={(e) => setMessage(e.target.value)} row={2} />
                            </div>

                            <div style={{
                                display: 'flex',
                                borderBottom: '1px solid gray',
                                margin: 'auto',
                                marginTop: "10px",
                                width: '90%',
                                backgroundColor: "white",
                                height: '40px',
                                color: 'gray',
                                alignItems: 'center',
                                marginBottom: "10px",
                                overflow: "hidden"
                            }}>
                                <ButtonBackground
                                    loading={loading}
                                    text={'Enquire Now'} type="primary" htmlType="submit" />
                            </div>
                        </form>
                    </div>
                }
            </div>
            <Modal
                visible={isEnquireModalVisible}
                onOk={handleEnquireModalOk}
                onCancel={handleEnquireModalCancel}
                footer={false}
            >
                <PopupDynamic
                    data={data}
                    setIsThankYouPage={setIsThankYouPage}
                    heading={cardData?.popupData?.heading}
                    button={cardData?.popupData?.button}
                    dialCode={dialCode}
                />

            </Modal>
        </div>
    );
};

export default SimpleForm;
