import {data} from "../../../config/data";
import React from 'react'
import DOMPurify from 'dompurify';
import Logo from "../../component/Logo/Logo";
const AboutTheDeveloper = () => {
    const sanitizedDescription = DOMPurify.sanitize(data?.aboutthedeveloper.para || '');
  return (
     

          <div
           
              className="container"
      >
          <div>
              <Logo/>
          </div>
     
            
              <h3
                  style={{
                      margin: "15px 0",
                      color: "rgba(2,43,45)",
                  }}
              >
              {data?.aboutthedeveloper?.heading}
              </h3>
              <div
                  style={{
                      lineHeight: "25px",
                  }}
                  dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
              />

          </div>
  )
}

export default AboutTheDeveloper