import React, { useState } from 'react';

import { FaPhoneAlt } from "react-icons/fa";

import { MdEmail } from 'react-icons/md';

import image from '../../../assets/whatsapp.png';

import { data } from '../../../config/data';

import { Modal } from 'antd';

import PopupDynamic from '../popup/PopupDynamic';

import Popup from '../popup/popup';



const CallingFooter = ({ dialCode, setIsThankYouPage }) => {

    const [isModalVisible, setIsModalVisible] = useState(false);





    const handleWhatsAppClick = () => {

        const recipientNumber = data?.details?.whatsappNumber;

        const whatsappMessage = data?.details?.whatsappMessage;

        const whatsappUrl = `https://wa.me/${recipientNumber}?text=${encodeURIComponent(whatsappMessage)}`;

        window.open(whatsappUrl, '_blank');

    };





    const showModal = () => {

        setIsModalVisible(true);

    };



    const handleOk = () => {

        setIsModalVisible(false);

    };



    const handleCancel = () => {

        setIsModalVisible(false);

    };







    return (

        <div style={{

            display: 'flex',

            justifyContent: 'space-around',

            background: 'rgba(2,43,45,1)', // Add opacity for better contrast 

            color: 'white',

            position: 'sticky',

            bottom: '0',

            zIndex: '34232342323423422342',

            padding: '10px 0' // Added padding for better spacing 

        }}>

            <div style={{

                textAlign: 'center',

                display: 'flex',

                justifyContent: 'center',

                alignItems: 'center'

            }}

                onClick={() => { showModal() }}

            >

                <MdEmail size={22} />

                <div style={{ marginLeft: '5px' }}>

                    Enquiry

                </div>

            </div>

            <a

                href={`tel:${data?.details?.phoneNumber}`}





                style={{

                    textDecoration: 'none',

                    textAlign: 'center',

                    display: 'flex',

                    justifyContent: 'center',

                    alignItems: 'center',

                    borderLeft: '1px solid white',

                    borderRight: '1px solid white',

                    padding: '0 20px',// Added padding for better spacing 

                    color: "white"

                }}>



                <FaPhoneAlt size={22} />

                <div style={{ marginLeft: '5px' }}>

                    Call

                </div>

            </a>

            <div style={{

                textAlign: 'center',

                display: 'flex',

                justifyContent: 'center',

                alignItems: 'center'



            }}



                onClick={handleWhatsAppClick}>

                <img

                    src={image}

                    alt="WhatsApp"

                    style={{

                        width: '22px',

                        marginRight: '5px'

                    }}

                    loading='lazy'

                />

                <div



                >

                    WhatsApp

                </div>

            </div>



            <Modal

                footer={false}

                visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>

                <PopupDynamic
                    data={data}
                    setIsThankYouPage={setIsThankYouPage}

                    heading={data?.cardData?.popupData?.heading}

                    button={data?.cardData?.popupData?.button}

                    dialCode={dialCode}

                />

            </Modal>

        </div>

    );

}



export default CallingFooter;
