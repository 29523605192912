export const data = {
  logo: "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2F33.webpc8d91679-6f6d-486b-add4-1c52f472c36b?alt=media&token=74a46e76-325f-4374-adff-f45c4d9492ec",

  projectName: "Brigade Valencia - Google Ads",

  //heroSection

  heroSection: {
    mobileImages: [
      "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fvalencia%20mobile%20hero%20section%201.webp7cfda35b-cf40-47c2-8e1e-74cddf0682f5?alt=media&token=6b15f767-a419-473e-a84e-ef2619301fac",
      "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fvalencia%20mobile%20hero%20section%202.webp830c1800-8dc2-4149-bf53-d38958320447?alt=media&token=f9282383-aa9e-42d2-9e47-c78e18f9450e",
    ],

    desktopImages: [
      "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fvalencia%20hero%20section%201.webp62cf5c20-5d17-49a1-a886-70e5ada21664?alt=media&token=5bb67a00-b6ca-4b90-b6f2-379f538f568c",

      "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fvalencia%20hero%20section%202.webpad84ef69-e4ce-4997-b963-5e6d6759bfb2?alt=media&token=dd7f8802-025d-4bf0-94d3-02e69c6f5e85",
    ],

    heading: "Overview",

    addressHeading: "Brigade Valencia",

    addressSubHeading:
      "At Hosur Road near Electronic City, Bengaluru By Brigade Group ",

    description:
      "Brigade Valencia is a premium 24-acre residential project in Electronic City, featuring 1, 2, 3 and 4 BHK luxury apartments ranging from 964 to 2276 sq ft. The gated community offers a comprehensive range of amenities, including badminton courts, squash, air hockey, foosball, a multipurpose gym, yoga and aerobics rooms, and a well-maintained swimming pool. An outdoor café provides a spot for residents to unwind and socialize. </br></br> Located in a thriving IT corridor, Brigade Valencia is near major roads like NICE Road and the Chennai Bangalore Expressway, ensuring excellent connectivity to key areas such as Koramangala and Sarjapur Road. The upcoming Bommasandra Metro Station will further enhance accessibility. </br></br> Brigade Valencia offers a luxury living experience in a well-connected and convenient location, ideal for professionals and families seeking a high-quality lifestyle with easy access to essential amenities and major workplace hubs.",

    button: "Download Brochure",

    popupData: {
      heading: "Download Brochure ",

      button: "Download Brochure",
    },
  },

  //card data

  cardData: {
    popupData: {
      heading: "Enquiry ",

      button: "Enquiry",
    },

    heading: "New Launch, RERA Approved",

    mainHeading: "Launch offers for first 150 EOIs",

    address: "Brigade Valencia - Brillio",

    subAddress: "At Electronic City, Bengaluru",

    fields: [
      { key: "24 Acres", label: "Land Area" },

      { key: "2029", label: "Possession" },

      { key: "G+22", label: "Floors" },
    ],

    buttonData: {
      discount: "Allotments Soon!",

      plantTime: "Luxury 1, 2, 3 & 4 BHKs",

      edition: "Valencia Brillio Cluster",
    },

    description: "Express your interest today!",

    price: "63 Lacs Onwards",

    // button: "Book your VC Now",

    rera: "RERA No. : RM/KA/RERA/1251/308/PR/040924/006986",
  },

  //Price

  price: {
    heading: "Tentative Price",

    tableHeading: ["Type", "Area", "Price"],

    plans: [
      {
        type: "1 BHK",

        sba: "640 sq ft (approx.)",

        price: "Rs. 63 Lacs",

        button: "Request For Details",
      },

      {
        type: "2 BHK",

        sba: "1145 sq ft (approx.)",

        price: "Rs. 1.05 Cr",

        button: "Request For Details",
      },

      {
        type: "2.5 BHK",

        sba: "1182 sq ft (approx.)",

        price: "On request",

        button: "Request For Details",
      },

      {
        type: "3 BHK + 2T",

        sba: "1510 sq ft (approx.)",

        price: "On request",

        button: "Request For Details",
      },

      {
        type: "3 BHK + 3T",

        sba: "1700 sq ft (approx.)",

        price: "On request",

        button: "Request For Details",
      },

      {
        type: "4 BHK",

        sba: "2490 sq ft (approx.)",

        price: "On request",

        button: "Request For Details",
      },
    ],
  },

  //Site & Floor Plan

  siteAndFloorPlan: {
    heading: "Site & Floor Plan",

    popupData: {
      heading: "Request for plan details",

      button: "Request for plan details ",
    },

    floorPlans: [
      {
        heading: "Master Plan Plan",

        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fvalencia%20Masterplan.webp557e8ed5-57b7-4b34-8c85-aec3b15da40f?alt=media&token=b5aa5bd2-26a7-4474-93c4-bd93c3bc55ec",

        button: "Master Plan",

        hoverButton: "Enquire Now",
      },

      {
        heading: "Floor Plan",

        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2F1%20bhk%20.webp7a8696c4-2365-446e-ba49-813a67a14fce?alt=media&token=776ff39d-65ac-4df5-911b-ac1816af890e",

        button: "1 BHK",

        hoverButton: "Enquire Now",
      },

      {
        heading: "Floor Plan",

        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2F2bhk.webpa30d647c-a396-4008-b31c-e7cd11909cfe?alt=media&token=559c18ca-4d48-4c73-96fd-b81137af975c",

        button: "2 BHK",

        hoverButton: "Enquire Now",
      },

      {
        heading: "Floor Plan",

        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2F3bhk.webp51620bc0-e681-47f0-b2d4-520bc5159b87?alt=media&token=76ff8321-23a5-488a-8332-3f920b429123",

        button: "3 BHK",

        hoverButton: "Enquire Now",
      },

      {
        heading: "Floor Plan",

        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2F4bhk.webpbc5e9dc2-6d11-405f-aa7f-2a5bc8e7d4ff?alt=media&token=15200f4a-0d66-4f28-abb3-036a5aa08f3f",

        button: "4 BHK",

        hoverButton: "Enquire Now",
      },
    ],
  },

  //AMENITIES

  amenities: {
    heading: "Amenities",

    image: false,

    images: [
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Famp.svgcdd31981-cadc-466e-b4ca-39b0646e70f0?alt=media&token=07c20efe-e5e5-4517-b133-26ee07a641c3",

        bottomSideHeading: "AMPHITHEATRE",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fbasketball-court.svg6a0442ac-3a47-492e-aa67-b33fd7672a47?alt=media&token=a3fcfd38-5006-4686-aeec-41ed7ad3f11a",

        bottomSideHeading: "BASKETBALL COURT",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fcricket.svgdc6f93ec-beec-4839-a7c3-e028d5ef5f00?alt=media&token=9891bd72-9d16-4d73-8774-13bd68b29639",

        bottomSideHeading: "CRICKET PRACTICE NET",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Ftable-tennis.svgcae56e9d-c1b2-43a8-8b36-62f46a0162a8?alt=media&token=8aeaa039-24fc-43af-90eb-a0e1145a3396",

        bottomSideHeading: "TABLE TENNIS",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fjogging.svg9aa328dd-c386-4bd4-9751-b5d87f9a8006?alt=media&token=10dcca65-1c34-4152-8ddb-a474574cb92a",

        bottomSideHeading: "JOGGING TRACK",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fgym.svga6462c92-2def-4067-9c45-c9013ee32c2b?alt=media&token=39cf2c2f-edb0-4aff-bf8d-bb9c63058dd3",

        bottomSideHeading: "GYM",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Freflexology.svg272a3d7e-481c-4f3e-a0a3-8f8ddfe88bfb?alt=media&token=e36ab038-8c51-4836-bee0-c1acfb379f01",

        bottomSideHeading: "REFLEXOLOGY PATHWAY",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fswm.svg0936427f-6d8c-44a1-b5ad-e172b28b65c4?alt=media&token=ccb6d048-d265-4c33-bd26-56b3f1ac1edb",

        bottomSideHeading: "SWIMMING POOL",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fkids.svge0ffa078-52ac-44ec-ae4e-1b843e328fe8?alt=media&token=1ffe893d-8644-4c14-b94b-bd531d98b773",

        bottomSideHeading: "KIDS PLAY AREA",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Ftrack-bicycle.svgb2654b89-e1be-4fdd-ade2-54cbbcf12175?alt=media&token=194a5e91-96c1-471d-8d8d-0c06dc9ad0db",

        bottomSideHeading: "CYCLING TRACK",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fyoga.svg220df747-446e-49da-afd7-eb796a43bee5?alt=media&token=8239ab9b-75a4-4f89-bb74-4dc49aaf6ceb",

        bottomSideHeading: "YOGA LAWN",
      },
    ],

    icons: [
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Famp.svgcdd31981-cadc-466e-b4ca-39b0646e70f0?alt=media&token=07c20efe-e5e5-4517-b133-26ee07a641c3",

        bottomSideHeading: "AMPHITHEATRE",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fbasketball-court.svg6a0442ac-3a47-492e-aa67-b33fd7672a47?alt=media&token=a3fcfd38-5006-4686-aeec-41ed7ad3f11a",

        bottomSideHeading: "BASKETBALL COURT",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fcricket.svgdc6f93ec-beec-4839-a7c3-e028d5ef5f00?alt=media&token=9891bd72-9d16-4d73-8774-13bd68b29639",

        bottomSideHeading: "CRICKET PRACTICE NET",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Ftable-tennis.svgcae56e9d-c1b2-43a8-8b36-62f46a0162a8?alt=media&token=8aeaa039-24fc-43af-90eb-a0e1145a3396",

        bottomSideHeading: "TABLE TENNIS",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fjogging.svg9aa328dd-c386-4bd4-9751-b5d87f9a8006?alt=media&token=10dcca65-1c34-4152-8ddb-a474574cb92a",

        bottomSideHeading: "JOGGING TRACK",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fgym.svga6462c92-2def-4067-9c45-c9013ee32c2b?alt=media&token=39cf2c2f-edb0-4aff-bf8d-bb9c63058dd3",

        bottomSideHeading: "GYM",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Freflexology.svg272a3d7e-481c-4f3e-a0a3-8f8ddfe88bfb?alt=media&token=e36ab038-8c51-4836-bee0-c1acfb379f01",

        bottomSideHeading: "REFLEXOLOGY PATHWAY",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fswm.svg0936427f-6d8c-44a1-b5ad-e172b28b65c4?alt=media&token=ccb6d048-d265-4c33-bd26-56b3f1ac1edb",

        bottomSideHeading: "SWIMMING POOL",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fkids.svge0ffa078-52ac-44ec-ae4e-1b843e328fe8?alt=media&token=1ffe893d-8644-4c14-b94b-bd531d98b773",

        bottomSideHeading: "KIDS PLAY AREA",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Ftrack-bicycle.svgb2654b89-e1be-4fdd-ade2-54cbbcf12175?alt=media&token=194a5e91-96c1-471d-8d8d-0c06dc9ad0db",

        bottomSideHeading: "CYCLING TRACK",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fyoga.svg220df747-446e-49da-afd7-eb796a43bee5?alt=media&token=8239ab9b-75a4-4f89-bb74-4dc49aaf6ceb",

        bottomSideHeading: "YOGA LAWN",
      },
    ],
  },

  //Gallery

  gallery: {
    heading: "Gallery",

    images: [
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fvalencia_1.webp6180a542-463f-421f-b435-ec5d8758693c?alt=media&token=b1331540-79ee-4728-af1f-94d7704bca76",

        bottomSideHeading: "Artistic Impression",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fvalencia_2.webpa32d04e2-0fb9-459a-80d5-d862a3b0d53c?alt=media&token=6b08f11d-9725-455c-b9d7-cdaa2d481229",

        bottomSideHeading: "Artistic Impression",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fvalencia_3.webp9b72acca-4c93-4427-a5bb-46dd17d6b712?alt=media&token=1d0f4df3-8c76-4da1-86d6-e9a14b8c1c33",

        bottomSideHeading: "Artistic Impression",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fvalencia_4.webp98387eb3-7a48-43de-8ec9-9ed68e67379c?alt=media&token=92782cde-586f-47f7-97cc-fa4e3a2822a9",

        bottomSideHeading: "Artistic Impression",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fvalencia_5.webp5788d7e7-578d-4aba-922f-87a9a7e77f11?alt=media&token=024fe265-fe6e-401d-8f04-097798499eb1",

        bottomSideHeading: "Artistic Impression",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fvalencia_6.webp1846423b-e657-4478-94b6-af67d88a4165?alt=media&token=34001f08-18e7-4430-9f03-4d450f155aa0",

        bottomSideHeading: "Artistic Impression",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fvalencia_7.webpffc71ce2-ad05-43c8-8bfe-16f5beb41ee6?alt=media&token=6ed4f272-aa51-4361-bfd2-a6340697b8dd",

        bottomSideHeading: "Artistic Impression",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fvalencia_8.webp50297fba-6471-4484-ab02-1147aad0fc29?alt=media&token=dc5d1daa-97a3-41c0-8457-82d9778adae5",

        bottomSideHeading: "Artistic Impression",
      },
    ],
  },

  //LOCATION

  location: {
    heading: "Location",

    mapView: {
      heading: "Map View",

      mapLink:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3890.5912542075657!2d77.69710947400215!3d12.805022787495218!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae6d3b5ff54669%3A0x777def6b4e39022b!2sBrigade%20Valencia!5e0!3m2!1sen!2sin!4v1721672455367!5m2!1sen!2sin",

      button: "Know More About Location",

      popupData: {
        heading: "Explore Location",

        button: "Request Location",
      },
    },

    locationMap: {
      heading: "Location Map",

      imageLink:
        "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fvalencia%20location%20mao.webpc8f87fee-443f-4e5d-a1c8-ba3deb84f3a0?alt=media&token=654fc637-6907-406d-810b-a2ce043aedc1",

      button: "View Location Map",

      popupData: {
        heading: "Explore Location",

        button: "Request Location",
      },
    },
  },

  //Virutal  Taur

  virtualSiteTour: {
    heading: "Virtual Tour",

    items: [
      {
        heading: "VIRTUAL SITE TOUR",

        subHeading: "Brigade Valencia at Electronic City, Bengaluru",

        bottomSideHeading: "Artistic Impression",

        topSideHeading: "",

        backgroundImage:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fvalencia%20Virtual%20Tour.webpcf717e29-c982-4dfc-934b-6089a39ee17f?alt=media&token=e6499b06-117d-41ad-a488-2592dfffd1a6",

        popupData: {
          heading: "Request For Virtual Site Tour",

          button: "Virtual Site Tour",
        },
      },

      {
        heading: "SAMPLE FLAT",

        subHeading: "Brigade Valencia at Electronic City, Bengaluru",

        bottomSideHeading: "Artistic Impression",

        // topSideHeading: "Unleash Your Golfing Skills on Breathtaking 9-Hole Chip & Golf Greens",

        backgroundImage:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fvalencia%20sample%20apt.webp18f561ff-035d-4e6a-bb69-3cd8b2e04d87?alt=media&token=3669569e-7883-41c6-a6bc-221b0e4cf413",

        popupData: {
          heading: "Request For Virtual Site Tour",

          button: "Virtual Site Tour",
        },
      },
    ],
  },

  //about the developer

  aboutthedeveloper: {
    heading: "About The Developer",

    para: "Brigade Enterprises Limited, an Indian real estate and property development company, is headquartered in Bangalore. Operating under the Brigade Group, the company has a presence in several cities, including Mangalore, Mysore, Chennai, Kochi, Hyderabad, Chikmagalur, and Ahmedabad, and also maintains a representative office in Dubai. Founded by M.R. Jaishankar in 1986, the Brigade Group has received national and international awards in the construction industry.</br></br>The Brigade Group offers property management services, hospitality, and education across various major cities in South India. Additionally, the group manages the Brigade Foundation, a not-for-profit trust. The company has established its prominence in real estate with developments like the first lifestyle enclave and Brigade Exotica, recognized as the tallest residential building in Bangalore.",
  },

  footer: {
    topLine: "Channel Partner RERA No. : RC/REA/HARERA/GGM/1327/922/2O2O",

    bottomLine: "Project RERA No. : RM/KA/RERA/1251/308/PR/040924/006986",

    link: "https://rera.karnataka.gov.in/",

    disclimer:
      "This is not the official website of the developer & property, it belongs to an authorised channel partner for information purposes only. All rights for logos & images are reserved to the developer. Thank you for visiting our website. This disclaimer Disclaimer is applicable to this website and all microsites and websites owned by us. By using or accessing this website you agree with the Disclaimer without any qualification or limitation. This website is in the process of being updated. By accessing this website, the viewer confirms that the information including brochures and marketing collaterals on this website are solely for informational purposes only and the viewer has not relied on this information for making any booking/purchase in any project of the company. Nothing on this website, constitutes advertising, marketing, booking, selling or an offer for sale, or invitation to purchase a unit in any project by the company. The company is not liable for any consequence of any action taken by the viewer relying on such material/information on this website.",

    footerAllRight:
      "",
  },

  details: {
    whatsappNumber: "+919990887599",

    phoneNumber: "+919990887599",

    whatsappMessage:
      "Hi, I am interested in Brigade Valencia. Please share the details with me.",
  },

  formData: {
    placeHolder: "Please suggest a time (in IST) to get in touch.",
    phonePlaceholder : "Phone number with country code",
    popup: {
      rows: 3,
    },
    sideFormRows: 2,
  },

  //thank you form

  thankYouPage: {
    heading: "Thank You",

    thankyouText: "for filling the form",

    image:
      "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fvalencia%20thank%20you.webp0d7c092f-0053-402c-94b0-f17e405d40bf?alt=media&token=ff9976f0-e033-41e5-b9a9-e388c1022014",

    appreciateLines: {
      lineOne: "We appreciate your interest",

      lineTwo: "and will get back to you shortly.",
    },
  },

  disclaimer: ` 

  <section> 

    <h2>Disclaimer</h2> 

    <p> 

      This website is only for the purpose of providing information regarding real estate projects in different regions. By accessing this website, the viewer confirms that the information including brochures and marketing collaterals on this website is solely for informational purposes and the viewer has not relied on this information for making any booking/purchase in any project of the company. Nothing on this website constitutes advertising, marketing, booking, selling or an offer for sale, or invitation to purchase a unit in any project by the company. The company is not liable for any consequence of any action taken by the viewer relying on such material/ information on this website. 

    </p> 

    <p> 

      Please also note that the company has not verified the information and the compliances of the projects. Further, the company has not checked the RERA (Real Estate Regulation Act 2016) registration status of the real estate projects listed herein. The company does not make any representation in regards to the compliances done against these projects. You should make yourself aware of the RERA registration status of the listed real estate projects before purchasing property. 

    </p> 

    <p> 

      This site is for information purposes only and should not be treated as the official website. 

    </p> 

  </section> 

 

  <section> 

    <h2>Privacy Policy</h2> 

    <h3>Updation of privacy policy</h3> 

    <p> 

      This privacy policy is subject to undergo change and review without any prior notice or approval. So to keep yourself updated on the changes introduced, please keep visiting and reviewing the terms and conditions of this privacy policy. 

    </p> 

 

    <h3>User information</h3> 

    <p> 

      By using our website, you agree to abide by the rules laid out by us and consent to the collection and use of all such information that you may furnish to, or through, our website. In some cases, while you visit our website, you may not need to provide any personal information. But in certain instances, we must have your personal information in order for us to grant you access to some of the links or sites. Such links/ pages may ask for your name, e-mail address, phone number, etc. The information furnished by you is used to provide relevant products and services and to acknowledge receipt of your communication or to send out information and updates to you. You have the option of requesting removal from our mailing list. We do not give away your personal information to any third party. 

    </p> 

 

    <h3>Security</h3> 

    <p> 

      To ensure security while transferring sensitive information, all the ongoing transmissions between client and server are encrypted using advanced and standard protocols. We also practice restricted access by employees and hold them to high levels of confidentiality. 

    </p> 

 

    <h3>Use of cookies</h3> 

    <p> 

      We may use cookies for security, session continuity, and customization purposes. In case of a user opting to reject a cookie, he/ she may not be able to gain access to some of the limited services or use some features of the site. 

    </p> 

 

    <p> 

      In case of any queries or suggestions regarding the privacy statement or your dealings with this website, please contact: 

    </p> 

  </section> 

`,

  popup: {
    leftSide: {
      heading: "We Promise",

      iconsImages: [
        {
          label: "Instant Call Back",

          imageLink:
            "https://firebasestorage.googleapis.com/v0/b/imageuploader-7809e.appspot.com/o/images%2Fcall-back-icon-16.jpgfeb2b5f4-9b58-4eae-87e9-c8aca81d5e45?alt=media&token=bb54e66f-5637-4ca0-9f40-2008ee8b2802",
        },

        {
          label: "Project Video Conferencing",

          imageLink:
            "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FVC.svgcb712f12-aac9-492a-a2f7-74c63a3918ea?alt=media&token=3848f904-807c-4e6c-9751-832759017e10",
        },

        {
          label: "Unmatched Price",

          imageLink:
            "https://firebasestorage.googleapis.com/v0/b/imageuploader-7809e.appspot.com/o/images%2Fbest-deal-badge-icon-best-deal-banners-badge-sticker-sign-tag-best-offer-modern-style-illustration-vector.jpg2c5a7144-c7c9-4913-b229-5280ed00fdc4?alt=media&token=ddf9f9c3-d403-4961-9557-850792a7b112",
        },
      ],
    },

    rightSide: {
      heading: "Project Highlights",

      points: [
        "RERA Received Now",

        "Excellent Connectivity",

        "Spanish Themed Living",

        "Preference for NRIs",
      ],
    },
  },

  sideForm: {
    topBarLeft: {
      label: "Organise Site Visit",

      popupData: {
        heading: "Request For Virtual Site Tour",

        button: "Virtual Site Tour",
      },

      topBarRight: {
        label: "Whatsapp",

        imageLink: "",
      },
    },

    buttonAfterCallButton: {
      label: "Request for Call Back",

      popupData: {
        heading: "Request For Call Back",

        button: "Request For Call Now ",
      },
    },
  },

  backendUrl: "https://landing-pages-backend.onrender.com",
};

//image ratio

// hero = >

//   desktop : 1183*730

//   mobile : 390*400

//logo =>92*54

//price => 420*227

// floorPlan => 425*319

//amenites => square

//gallery => square

//location =>687*310

//video => 569*323
