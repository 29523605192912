import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import React from 'react';

const IconCarousel = ({ icons }) => {
    const groupIcons = (icons) => {
        const groupedIcons = [];
        for (let i = 0; i < icons.length; i += 3) {
            groupedIcons.push(icons.slice(i, i + 3));
        }
        return groupedIcons;
    };

    const groupedIcons = groupIcons(icons);

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 2
        },
        desktop: {
            breakpoint: { max: 3000, min: 800 },
            items: 2
        },
        tablet: {
            breakpoint: { max: 800, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <div>
            <Carousel
                draggable={true}
                infinite
                autoPlay
                autoPlaySpeed={1000}
                responsive={responsive}

            >
                {groupedIcons.map((group, index) => (
                    <div

                        key={index} style={{  justifyContent: 'space-around', width: "80%" ,margin : "0 30px"}}>
                        {group.map((icon, idx) => (
                            <div key={idx}
                                style={{
                                    width: "100%",
                                
                                    borderBottom: idx === 0 || idx ===1? "1px solid gray" : "none",
                                    display: 'flex',
                                    justifyContent: "space-between",
                                    alignItems: 'center',
                                    padding: "10px 15px",
                                    height : "80px"
                                }}
                            
                            >

                        <img
                            style={{
                                        width: "70px",
                                height : "50px"

                            }}

                            src={icon.imageLink}
loading="lazy"
                        />

                                <h3
                                    className ="smallTextInIcons"
                                    style={{ textAlign: "left", flex: "1", paddingLeft: "30px" }}>{icon?.bottomSideHeading}</h3>
                    </div>
                ))}
        </div>
    ))
}
            </Carousel >
        </div >
    );
};

export default IconCarousel;
