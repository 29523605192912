import React from 'react'
import './Video.css'
import { MdOutlinePlayCircleFilled } from "react-icons/md";

const VideoView = (
  {
    heading, subHeading, topSideHeading,
    bottomSideHeading,
    backgroundImage
  }
) => {
  return (
    <div
      className='videoView'
    
    >
     
      {
        topSideHeading &&
        <div
            className='topSideHeading'
           >
          {topSideHeading}
        </div>
      }

      <div
        style={{
        
          zIndex: "1",
          position: "absolute"
        }}
        className='videoCenter'>
        <MdOutlinePlayCircleFilled
          style={{
            fontSize: "90px",
            color: "white"
          }}
        />

        <h2>{heading}</h2>
        <h5>{subHeading}</h5>

      </div>


      {
        bottomSideHeading && <div
          className='bottomSideHeading'
        >
          {bottomSideHeading}
        </div>

      }
      <img
        style={{
          width: "100%",
          // height: "100%",
          backgroundSize: "cover",
         
          left: '0',
          zIndex: "0",
          
        }}
        loading='lazy'
        src={backgroundImage}
      />
    </div>
  )
}

export default VideoView
