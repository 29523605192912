import React from 'react';
import { Carousel } from 'antd';
import { useMediaQuery } from 'react-responsive';
import './ResponsiveCarousel.css'

const ResponsiveCarousel = ({ images }) => {
    const isBigScreen = useMediaQuery({ query: '(min-width: 1200px)' });
    const isMediumScreen = useMediaQuery({ query: '(min-width: 768px) and (max-width: 1199px)' });
    const isSmallScreen = useMediaQuery({ query: '(max-width: 767px)' });

    const getImagesPerSlide = () => {
        if (isBigScreen) return 6;
        if (isMediumScreen) return 4;
        if (isSmallScreen) return 2;
        return 2;
    };

    const imagesPerSlide = getImagesPerSlide();
    const slides = [];
    for (let i = 0; i < images.length; i += imagesPerSlide) {
        slides.push(images.slice(i, i + imagesPerSlide));
    }

    const getGridTemplate = () => {
        if (isBigScreen) return 'repeat(2, 1fr) / repeat(3, 1fr)';
        if (isMediumScreen) return 'repeat(2, 1fr) / repeat(2, 1fr)';
        return 'repeat(2, 1fr) / repeat(1, 1fr)';
    };

    const gridTemplate = getGridTemplate();

    return (
        <Carousel
            autoplay
            arrows
        >
            {slides.map((slide, index) => (
                <div 
                    key={index} className="carousel-slide">
                    <div
                        style={{
                            display: 'grid',
                            gridTemplate: gridTemplate,
                            position: 'relative',
                            margin: "auto",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        {slide.map((image, idx) => {
                            return (
                            <div
                                style={{              
                                    overflow: 'hidden',
                                        position: "relative"
                                        ,
                                       padding : "0",
                                        margin: "5px auto",
                                        borderRadius: "20px",
                                }} >
                                    <div style={{ position: '', width: '100%', height: '100%' }}>
                                        <img
                                            key={idx}
                                            src={image?.imageLink}
                                            alt={`slide-${index}-image-${idx}`}
                                            style={{
                                                opacity: '0.9',
                                                width: '100%',
                                                borderRadius: '20px',
                                                height: '100%',
                                                // position: 'absolute',
                                                top: 0,
                                                left: 0
                                            }}
                                            loading='lazy'
                                        />
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                backgroundColor: 'black',
                                                opacity: 0.4,
                                                borderRadius: '20px'
                                            }}
                                        />
                                    </div>
                                <h1
                                    style={{
                                        position: 'absolute',
                                            bottom: "30px",
                                            borderLeft: "5px solid rgba(2,43,45)",
                                            paddingLeft: '2px',
                                            color: "white",
                                            left: "10px",
                                            
                                        
                                    }}
                                
                                >
                                        {image?.bottomSideHeading}
                                    </h1>
                            </div>
                        )
                        }
                        
                        )}
                    </div>
                </div>
            ))}
        </Carousel>
    );
};

export default ResponsiveCarousel;
